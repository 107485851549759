import React, { FC, ReactNode } from "react"
import { Box, Flex, FlexProps, Image, Text, TextProps } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VFlex } from "~/components"

import photo from "./assets/nadia.webp?url"
import assistant from "./assets/assistant.webp?url"
import sign from "./assets/sign.webp?url"
import gift from "./assets/gift.webp?url"
import bubbles from "./assets/bubbles.svg?url"
import { Trans, useTranslation } from "react-i18next"

type DirectionVariant = "RIGHT" | "LEFT"

const BubbleBox: FC<FlexProps & { variant?: DirectionVariant }> = ({
  variant = "LEFT",
  ...props
}) => (
  <Flex
    direction={variant === "RIGHT" ? "row" : "row-reverse"}
    w="fit-content"
    alignItems="flex-start"
  >
    <Box
      paddingX={3}
      paddingY="11px"
      backgroundColor="white"
      borderRadius="24px"
      shadow="LoviShort"
      w="full"
      sx={{
        "& em": {
          fontWeight: 700,
          fontStyle: "normal",
        },
      }}
      {...props}
    />
    <Box
      sx={{
        /* content: "' '", */
        minWidth: "20px",
        minHeight: "20px",
        backgroundImage: bubbles,
        backgroundRepeat: "no-repeat",
        transform: `${variant === "RIGHT" ? "scaleX(-1)" : ""} translate(6px, -1.5px)`,
        position: "absolute",
      }}
    />
  </Flex>
)

const StaticCitationNadya: FC = () => {
  const { t } = useTranslation()
  return (
    <Box mb={4} bg={`bottom right / 209px 302px no-repeat url(${photo})`} w="full">
      <VFlex paddingY="44px" paddingLeft={6} paddingRight="72px" gap={4}>
        <VFlex gap={1}>
          <Box fontFamily="heading" textStyle="Header/Secondary">
            Nadia Kapleva
          </Box>
          <Box textStyle="Paragraph/Secondary">{t("static_nadya.director")}</Box>
        </VFlex>
        <BubbleBox
          minH="146px"
          justifyContent="center"
          gap={2}
          textStyle="Paragraph/AccentPrimary"
          variant="LEFT"
        >
          <Box width="255px">
            <Trans
              i18nKey="static_nadya.lovi_knows"
              components={{
                em: <em />,
              }}
            />
          </Box>
          <Image src={sign} alt="" h="44px" w="117px" />
        </BubbleBox>
      </VFlex>
    </Box>
  )
}

const StaticCitationAssistant: FC = () => (
  <Box bg={`center right / 209px 254px no-repeat url(${assistant})`} minH="209px">
    <VFlex paddingY="40px" paddingLeft={6} paddingRight="72px" gap={6}>
      <Flex alignItems="center" gap={2} maxW="301px">
        <Image src={gift} alt="" w="40px" h="40px" />
        <Box fontFamily="heading" textStyle="Header/Secondary">
          Your Lifetime
          <br />
          Benefit:
        </Box>
      </Flex>
      <BubbleBox justifyContent="center" textStyle="Paragraph/AccentPrimary" variant="LEFT">
        Get help from <em>science-backed</em> skincare consultations with the Lóvi AI Assistant
      </BubbleBox>
    </VFlex>
  </Box>
)

const StaticCitationAssistant2: FC = () => {
  const { t } = useTranslation()
  return (
    <Box bg={`center right / 209px 254px no-repeat url(${assistant})`} minH="209px">
      <VFlex paddingY="40px" paddingLeft={6} paddingRight="72px" gap={6}>
        <Flex alignItems="center" gap={2} maxW="301px">
          <Image src={gift} alt="" w="40px" h="40px" />
          <Box whiteSpace="pre-line" fontFamily="heading" textStyle="Header/Secondary">
            {t("citation_assistant.title")}
          </Box>
        </Flex>
        <BubbleBox
          whiteSpace="pre-line"
          justifyContent="center"
          textStyle="Paragraph/AccentPrimary"
        >
          <Trans i18nKey="citation_assistant.description" components={{ em: <em /> }}></Trans>
        </BubbleBox>
      </VFlex>
    </Box>
  )
}

const CiteItem: FC<{
  imageSrc: string
  title: string
  children: ReactNode
  variant?: DirectionVariant
}> = ({ imageSrc, title, variant = "LEFT", children }) => (
  <Flex gap={2} direction={variant === "LEFT" ? "row-reverse" : "row"}>
    <VFlex gap={1}>
      <Image src={imageSrc} alt="" minW="64px" sx={{ aspectRatio: 1 }} />
      <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary" textAlign="center">
        {title}
      </Box>
    </VFlex>
    <BubbleBox variant={variant}>{children}</BubbleBox>
  </Flex>
)

const StaticCitationMedicalAdvisers: FC = () => (
  <VFlex>
    <Image
      src="https://storage.pora.ai/bfd2caf530e5d5fddbb54d57b18885ba.webp"
      alt=""
      w="full"
      sx={{ aspectRatio: 375 / 251 }}
    />
    <VFlex marginY={8} marginX={6} gap={6}>
      <CiteItem
        imageSrc="https://storage.pora.ai/099e3b920d87b75e07e761989aa26fd9.webp"
        title="Aishani"
        variant="RIGHT"
      >
        Lóvi Cosmetics Scanner identifies <em>potentially harmful</em> ingredients
      </CiteItem>
      <CiteItem
        imageSrc="https://storage.pora.ai/7c4e3ff759c5e06cb913ee0e938e1426.webp"
        title="Nadia"
        variant="LEFT"
      >
        The AI&nbsp;Face&nbsp;Scanner detects and <em>analyzes skin issues</em>{" "}
        for&nbsp;personalized insights
      </CiteItem>
      <CiteItem
        imageSrc="https://storage.pora.ai/389afa0395b5496092c96df6e260bfae.webp"
        title="Hanna"
        variant="RIGHT"
      >
        Lóvi knows what products will fit your skin <em>best out of&nbsp;500K+ products</em> in our
        database
      </CiteItem>
    </VFlex>
  </VFlex>
)

export const StaticCitationNadyaBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" paddingTop={6} {...props}>
    <StaticCitationNadya />
  </BlockWrapper>
)

export const StaticCitationAssistantBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" {...props}>
    <StaticCitationAssistant />
  </BlockWrapper>
)

export const StaticCitationAssistant2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" {...props}>
    <StaticCitationAssistant2 />
  </BlockWrapper>
)

export const StaticCitationMedicalAdvisersBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    variant="transparent"
    header="All the&nbsp;programs are&nbsp;approved by&nbsp;our&nbsp;Medical Advisers"
    {...props}
  >
    <StaticCitationMedicalAdvisers />
  </BlockWrapper>
)
