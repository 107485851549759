import React, { FC, useCallback } from "react"
import { Button } from "@chakra-ui/react"
import { useDeepLink } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useTranslation } from "react-i18next"

export const DownloadButton: FC = () => {
  const deepLink = useDeepLink()
  const log = useAmplitude()
  const { t } = useTranslation()
  const onClickLink = useCallback(() => {
    log.successContinuePress()
    window.location.href = deepLink // TODO use redirect here
  }, [deepLink, log])

  return (
    <Button onClick={onClickLink} variant="next" w="full">
      {t("instructions.get_app")}
    </Button>
  )
}
