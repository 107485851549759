import React, { FC, Suspense, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Box, Button, Heading, Tag } from "@chakra-ui/react"
import { AnimatePresence } from "framer-motion"

import { VFlex } from "~/components"
import { CheckoutProduct, SkinCareProgram } from "~/generated/interview_service"
import { CurrencyCode } from "~/utils"

import { useBlockProperties, useCountry, useUserId } from "~/store/selectors"
import { setCountryCode } from "~/store/actions"
import { getCountryCode, getProgramData } from "~/api/api"
import { logError } from "~/utils/logError"
import { useNavigateWithSearchParams } from "~/utils/useNavigateWithSearchParams"

import { PaymentStatus, PaymentSuccessVariant, WhyLoviPart } from "../components"

import {
  CartSubscriptionText,
  CheckoutListItem,
  CheckoutSummaryCard,
  useCheckoutList,
} from "./CheckoutList"
import { HowWePickProductsList, ActionButtonPanel, PromoListCard } from "./components"
import { prevPrice } from "./constants"

export const UpsaleShopifyProducts: FC<{
  products: CheckoutProduct[]
  currency: CurrencyCode
  period: string
  canRemove?: boolean
  onCheckout: (productLinks: string[]) => void
  onCancel: () => void
}> = ({ products: _products, currency, period, canRemove = false, onCheckout, onCancel }) => {
  const { products, productsToCheckout, totalPrice, onRemoveItem, onRestoreItem } =
    useCheckoutList(_products)
  const onCheckoutFn = useCallback(() => {
    onCheckout(
      products
        .filter(({ _id }) => productsToCheckout.has(_id))
        .map(({ checkout_link_parameters }) => checkout_link_parameters)
    )
  }, [onCheckout, products, productsToCheckout.size])

  return (
    <VFlex w="fit-content" gap={2} px={6} mt="56px">
      <Heading as="h1" textStyle="Header/Primary" textAlign="center" mb={4}>
        Get&nbsp;a&nbsp;tailored product&nbsp;kit delivered to&nbsp;you&nbsp;monthly
      </Heading>
      <PromoListCard />
      <VFlex gap={2} w="full">
        <AnimatePresence initial={false}>
          {products.map(({ _id: id, price, image, title, brand, step_name }) => (
            <CheckoutListItem
              key={id}
              isSelectedToOrder={productsToCheckout.has(id)}
              image={image}
              title={title}
              subTitle={brand}
              price={price}
              currency={currency}
              slotTags={<Tag variant="cartItem">{step_name.replace("_", " ")}</Tag>}
              canBeRemoved={canRemove}
              onRemove={onRemoveItem(id)}
              onRestore={onRestoreItem(id)}
            />
          ))}
          <CheckoutSummaryCard
            currency={currency}
            price={totalPrice}
            previousPrice={prevPrice(totalPrice)}
            period={period}
          />
        </AnimatePresence>
      </VFlex>
      <Box>
        <CartSubscriptionText mb={2} price={totalPrice} currency={currency} period={period} />
        <HowWePickProductsList px={6} pt={6} />
        <WhyLoviPart pt={2} mb={4} />
        <ActionButtonPanel>
          <Button
            variant="action"
            isDisabled={productsToCheckout.size == 0}
            w="full"
            onClick={onCheckoutFn}
          >
            Continue to purchase
          </Button>
          <Button variant="secondary" w="full" onClick={onCancel}>
            Continue without buying
          </Button>
        </ActionButtonPanel>
      </Box>
    </VFlex>
  )
}

export const UpsaleShopifyProductsContainer: FC = () => {
  const userId = useUserId() as UserId
  const country = useCountry()
  const blockProperties = useBlockProperties()
  const canRemove = Boolean(blockProperties["PB_UPSALE_SHOPIFY_PRODUCTS.can_remove"])
  const navigate = useNavigateWithSearchParams()
  const [paymentPageVariant, setPaymentPageVariant] = useState<PaymentSuccessVariant>()

  const [data, setData] = useState<SkinCareProgram["checkout_data"] | undefined>(undefined)
  const [error, setError] = useState<Error | unknown | undefined>(undefined)

  const onCheckout = useCallback(
    (link_parameters: string[]) => {
      const { checkout_link_prefix, checkout_link_suffix } = data!
      const link = [
        checkout_link_prefix,
        [...link_parameters, checkout_link_suffix].join("%26"),
      ].join("")
      navigate(link)
    },
    [navigate, data]
  )

  const onCancel = useCallback(() => {
    setPaymentPageVariant("shopifyProductsFail")
  }, [setPaymentPageVariant])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!country) {
      getCountryCode().then((countryCode) => {
        dispatch(setCountryCode(countryCode))
      })
      return
    }
    async function fetchData() {
      try {
        const data = await getProgramData({ userId, country })
        if (data?.program?.checkout_data) {
          setData(data.program.checkout_data)
        }
      } catch (error) {
        logError(error as string)
        setError(error)
      }
    }
    fetchData()
  }, [userId, country, dispatch])

  return (
    <>
      {!paymentPageVariant && (
        <Suspense fallback={null}>
          {(error as string) && <Box>Error: {JSON.stringify(error)}</Box>}
          {data && (
            <UpsaleShopifyProducts
              products={data.products ?? []}
              currency={data.price_currency ?? "USD"}
              period={data.subscription_term}
              canRemove={canRemove}
              onCheckout={onCheckout}
              onCancel={onCancel}
            />
          )}
        </Suspense>
      )}
      {paymentPageVariant && <PaymentStatus variant={paymentPageVariant} />}
    </>
  )
}
