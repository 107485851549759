// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: recurly.proto

/* eslint-disable */

/** Subscription represents a subscription with its plan code and add-ons */
export interface Subscription {
  plan_code: string;
}

/** PurchaseRequest represents the structure of the purchase request */
export interface PurchaseRequest {
  user_id: string;
  subscription: Subscription | undefined;
  currency: string;
}

/** PurchaseResponse represents the response for a purchase request */
export interface PurchaseResponse {
  status: string;
  info: string;
}

export interface PaymentIntentRequest {
  user_id: string;
  token: string;
}

export interface PaymentIntentResponse {
  status: string;
  info: string;
}

export interface CancelSubscriptionRequest {
  user_id: string;
  subscription_id: string;
}

export interface CancelSubscriptionResponse {
  status: string;
  info: string;
}

export const Subscription = {
  fromJSON(object: any): Subscription {
    return { plan_code: isSet(object.plan_code) ? globalThis.String(object.plan_code) : "" };
  },

  toJSON(message: Subscription): unknown {
    const obj: any = {};
    if (message.plan_code !== "") {
      obj.plan_code = message.plan_code;
    }
    return obj;
  },
};

export const PurchaseRequest = {
  fromJSON(object: any): PurchaseRequest {
    return {
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      subscription: isSet(object.subscription) ? Subscription.fromJSON(object.subscription) : undefined,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
    };
  },

  toJSON(message: PurchaseRequest): unknown {
    const obj: any = {};
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.subscription !== undefined) {
      obj.subscription = Subscription.toJSON(message.subscription);
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    return obj;
  },
};

export const PurchaseResponse = {
  fromJSON(object: any): PurchaseResponse {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      info: isSet(object.info) ? globalThis.String(object.info) : "",
    };
  },

  toJSON(message: PurchaseResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.info !== "") {
      obj.info = message.info;
    }
    return obj;
  },
};

export const PaymentIntentRequest = {
  fromJSON(object: any): PaymentIntentRequest {
    return {
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      token: isSet(object.token) ? globalThis.String(object.token) : "",
    };
  },

  toJSON(message: PaymentIntentRequest): unknown {
    const obj: any = {};
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    return obj;
  },
};

export const PaymentIntentResponse = {
  fromJSON(object: any): PaymentIntentResponse {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      info: isSet(object.info) ? globalThis.String(object.info) : "",
    };
  },

  toJSON(message: PaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.info !== "") {
      obj.info = message.info;
    }
    return obj;
  },
};

export const CancelSubscriptionRequest = {
  fromJSON(object: any): CancelSubscriptionRequest {
    return {
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      subscription_id: isSet(object.subscription_id) ? globalThis.String(object.subscription_id) : "",
    };
  },

  toJSON(message: CancelSubscriptionRequest): unknown {
    const obj: any = {};
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.subscription_id !== "") {
      obj.subscription_id = message.subscription_id;
    }
    return obj;
  },
};

export const CancelSubscriptionResponse = {
  fromJSON(object: any): CancelSubscriptionResponse {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      info: isSet(object.info) ? globalThis.String(object.info) : "",
    };
  },

  toJSON(message: CancelSubscriptionResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.info !== "") {
      obj.info = message.info;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
