import React, { FC, ReactNode } from "react"
import { Box, BoxProps, Button, ButtonProps, Flex, Text } from "@chakra-ui/react"
import { useSelectedPrice } from "~/store/selectors"
import { formatPercent } from "~/utils"

import { FloatPanel } from "./FloatPanel"
import { VFlex } from "../VFlex"
import { useTranslation } from "react-i18next"

export type TimerFloatVariant = 1 | 2 | 3 | 4 | "5top" | "5bottom" | 6

type TimerFloatProps = {
  onClick: () => void
  visible: boolean
  timer: ReactNode
  offerTitle?: string
  variant?: TimerFloatVariant
  buttonText?: string
}
type TimerFloatInstanceProps = Omit<TimerFloatProps, "variant">

const TimerFloatContent: FC<
  TimerFloatInstanceProps & { buttonVariant?: ButtonProps["variant"] }
> = ({ onClick, timer, buttonText = "Get the offer", buttonVariant = "solidAction" }) => {
  const { t } = useTranslation()
  return (
    <>
      <Text as="div" fontFamily="heading" textStyle="Header/Primary" color="Base/baseDisabled">
        <Text
          as="span"
          color="Base/basePrimary"
          display="inline-block"
          sx={{ fontVariantNumeric: "lining-nums tabular-nums" }}
        >
          {timer}
        </Text>
        <Text as="span"> {t("timer.left")}</Text>
      </Text>
      <Box flexGrow={1}>
        <Button w="full" variant={buttonVariant} onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </>
  )
}

export const TimerFloat3: FC<TimerFloatInstanceProps> = (props) => (
  <>
    <Box height={props.visible ? "72px" : "0"} />
    <FloatPanel visible={props.visible} direction="top">
      <Flex
        pos="static"
        maxWidth="450px"
        width="full"
        height="72px"
        paddingLeft={6}
        paddingRight={2}
        paddingY={2}
        gap="20px"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="LoviMedium"
        bgColor="Base/neutralPrimary"
      >
        <TimerFloatContent {...props} />
      </Flex>
    </FloatPanel>
  </>
)

export const TimerFloat4: FC<TimerFloatInstanceProps> = (props) => (
  <>
    <FloatPanel visible={props.visible} direction="bottom">
      <Flex
        pos="static"
        maxWidth="450px"
        width="full"
        height="92px"
        top={0}
        paddingLeft={6}
        paddingRight={3}
        paddingTop={3}
        alignItems="top"
        boxShadow="LoviMedium"
        bgColor="Base/neutralPrimary"
        borderTopRadius="brand24"
      >
        <Flex
          width="full"
          gap="20px"
          justifyContent="space-between"
          alignItems="center"
          h="min-content"
        >
          <TimerFloatContent buttonVariant="timerFloatAction" {...props} />
        </Flex>
      </Flex>
    </FloatPanel>
    <Box height={props.visible ? "92px" : "0"} />
  </>
)

const TimerFloat5Content: FC<TimerFloatInstanceProps> = ({
  onClick,
  timer,
  offerTitle,
  buttonText = "Get the offer",
}) => (
  <Flex width="full" gap={5} justifyContent="space-between" alignItems="center" h="min-content">
    <VFlex alignItems="flex-start">
      <Box textStyle="Paragraph/Secondary">{offerTitle}</Box>
      <Box fontFamily="heading" textStyle="Header/Monospaced">
        {timer}
      </Box>
    </VFlex>
    <Box flexGrow={1}>
      <Button w="full" variant="timerFloatAction" onClick={onClick}>
        {buttonText}
      </Button>
    </Box>
  </Flex>
)
export const TimerFloat5Top: FC<TimerFloatInstanceProps> = (props) => (
  <>
    <FloatPanel visible={props.visible} direction="bottom">
      <Flex
        pos="static"
        maxWidth="450px"
        width="full"
        height="84px"
        top={0}
        paddingLeft={6}
        paddingRight={3}
        paddingTop={4}
        paddingBottom={3}
        alignItems="top"
        boxShadow="LoviMedium"
        bgColor="Base/neutralPrimary"
        borderTopRadius="brand24"
      >
        <TimerFloat5Content {...props} />
      </Flex>
    </FloatPanel>
    <Box height={props.visible ? "84px" : "0"} />
  </>
)

export const TimerFloat5Bottom: FC<TimerFloatInstanceProps> = (props) => (
  <>
    <FloatPanel visible={props.visible} direction="top">
      <Flex
        pos="static"
        maxWidth="450px"
        width="full"
        height="84px"
        top={0}
        paddingLeft={6}
        paddingRight={3}
        paddingTop={3}
        paddingBottom={4}
        alignItems="bottom"
        boxShadow="LoviMedium"
        bgColor="Base/neutralPrimary"
        borderBottomRadius="brand24"
      >
        <TimerFloat5Content {...props} />
      </Flex>
    </FloatPanel>
    <Box height={props.visible ? "84px" : "0"} />
  </>
)

export const TimerFloat1: FC<TimerFloatInstanceProps> = ({
  onClick,
  visible,
  timer,
  offerTitle = "3-day offer expires in",
  buttonText = "Start the trial",
}) => (
  <>
    <Box height={visible ? "60px" : "0"} />
    <FloatPanel visible={visible}>
      <Flex
        pos="static"
        maxWidth="450px"
        width="full"
        top={0}
        bgColor="Base/neutralActive"
        px={4}
        py="10px"
        gap={2}
        alignItems="center"
        boxShadow="LoviMedium"
        justifyContent="space-between"
      >
        <Box textStyle="Subtitle/Secondary" textColor="Base/accentSecondary">
          {offerTitle} {timer}
        </Box>
        <Button minHeight="none" variant="timerFloatSmall" onClick={onClick}>
          {buttonText}
        </Button>
      </Flex>
    </FloatPanel>
  </>
)

const DiscountChip: FC<BoxProps> = (props) => (
  <Box
    display="inline-block"
    color="white"
    backgroundColor="Other/New"
    paddingY={0.5}
    paddingX={1.5}
    borderRadius="full"
    {...props}
  />
)

export const TimerFloat2: FC<TimerFloatInstanceProps & { discountText: string }> = ({
  visible,
  onClick,
  timer = null,
  offerTitle = "Your Offer",
  discountText = "-25%",
  buttonText = "Start the plan",
}) => {
  const { t } = useTranslation()
  return (
    <FloatPanel visible={visible}>
      <Flex
        direction="row"
        boxShadow="LoviMedium"
        backgroundColor="Base/neutralPrimary"
        paddingLeft={8}
        paddingRight={4}
        paddingY="10px"
        alignItems="center"
        justifyContent="space-between"
        minH="68px"
        maxWidth="450px"
        width="100%"
      >
        <Flex direction="column" gap={0.5}>
          <Box textStyle="Subtitle/Primary">
            {offerTitle} <DiscountChip textStyle="Subtitle/Tertiary">{discountText}</DiscountChip>
          </Box>
          <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary">
            {timer} {t("timer.left")}
          </Box>
        </Flex>
        <Button onClick={onClick} variant="solidActionSmall">
          {buttonText}
        </Button>
      </Flex>
    </FloatPanel>
  )
}

const TimerFloat2Container: FC<TimerFloatInstanceProps> = (props) => {
  const { economy_percent } = useSelectedPrice() ?? { economy_percent: "0" }
  return <TimerFloat2 discountText={`-${formatPercent(parseFloat(economy_percent))}`} {...props} />
}

const TimerFloat6: FC<TimerFloatInstanceProps & { discountText: string }> = ({
  visible,
  timer,
  onClick,
  buttonText,
  discountText,
}) => (
  <>
    {visible && <Box height="68px" />}
    <FloatPanel visible={visible}>
      <Flex
        pos="static"
        boxShadow="LoviMedium"
        maxWidth="450px"
        width="full"
        height="68px"
        paddingLeft={6}
        paddingRight={4}
        paddingY={2.5}
        alignItems="center"
        justifyContent="space-between"
        bgColor="Base/neutralPrimary"
      >
        <VFlex color="Base/basePrimary" gap={0.5}>
          <Box textStyle="Paragraph/Secondary">{discountText}</Box>
          <Box textStyle="Subtitle/Primary" sx={{ fontVariantNumeric: "lining-nums tabular-nums" }}>
            {timer}
          </Box>
        </VFlex>
        <Button w="fit-content" onClick={onClick} variant="solidActionSmall">
          {buttonText}
        </Button>
      </Flex>
    </FloatPanel>
  </>
)

const TimerFloat6Container: FC<TimerFloatInstanceProps> = (props) => {
  const { economy_percent } = useSelectedPrice() ?? { economy_percent: "0" }
  return (
    <TimerFloat6
      // eslint-disable-next-line no-irregular-whitespace
      discountText={`${formatPercent(parseFloat(economy_percent))} discount reserved for:`}
      {...props}
    />
  )
}

export const TimerFloat: FC<TimerFloatProps> = ({ variant = 1, ...props }) => {
  const Component = {
    1: TimerFloat1,
    2: TimerFloat2Container,
    3: TimerFloat3,
    4: TimerFloat4,
    "5top": TimerFloat5Top,
    "5bottom": TimerFloat5Bottom,
    6: TimerFloat6Container,
  }[variant]

  return <Component {...props} />
}
