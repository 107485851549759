import { useCallback } from "react"
import { useSelectedPrice } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMetaPixel } from "~/utils/analytics/useMetaPixel"
import { usePinterest } from "~/utils/analytics/usePinterest"
import { useTiktokPixel } from "~/utils/analytics/useTiktokPixel"
import { useSnapPixel } from "~/utils/analytics/useSnapPixel"

export const useLogInitiateCheckout = () => {
  const selectedPrice = useSelectedPrice()

  const logIa = useAmplitude()
  const logMp = useMetaPixel()
  const logTtp = useTiktokPixel()
  const logPin = usePinterest()
  const logSnap = useSnapPixel()

  const logCb = useCallback(() => {
    if (selectedPrice) {
      logIa.paymentFormShow()
      const productInfo = {
        priceId: selectedPrice.id,
        priceTitle: selectedPrice.title,
        value: selectedPrice.trial_price_cents / 100,
        currency: selectedPrice.currency_code,
        userEmail: "",
      }
      logPin.initiateCheckout(productInfo)
      logMp.initiateCheckout(productInfo)
      logTtp.initiateCheckout(productInfo)
      logSnap.initiateCheckout(productInfo)
    }
  }, [logMp, logTtp, logPin, logIa, selectedPrice])
  return logCb
}
