import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import en from "~/locales/en.json"
import es from "~/locales/es.json"
import { useCallback, useEffect } from "react"

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
}

const params = new URLSearchParams(window ? window.location.search : {})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    lng: params.get("lng") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

window.changeLanguage = (lng: string) => i18n.changeLanguage(lng)

export function useTranslationOrKey() {
  const { t, i18n } = useTranslation()
  const tOrKey = useCallback(
    (str?: string) => {
      if (str && i18n.exists(str)) {
        return t(str as any)
      } else {
        return str
      }
    },
    [t, i18n]
  )
  return {
    tOrKey,
  }
}

export function SetLanguageCSSVariable() {
  const { i18n } = useTranslation()

  useEffect(() => {
    // Update CSS variable with the current language
    document.documentElement.style.setProperty("--current-lang", `"${i18n.language}"`)
  }, [i18n.language])

  return null
}

export default i18n
