import React, { FC } from "react"
import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import { CurrencyCode, formatPrice } from "~/utils"
import { Timer, VFlex } from "~/components"
import tagBgSrc from "~/pages/Main/PriceSelectWithPaymentForm/assets/tagBg.webp"
import { Strike } from "~/shared/Strike"
import { useSelectedPrice } from "~/store/selectors"
import { BlockWrapper, BlockWrapperProps } from "~/pages/Main/Blocks/BlockWrapper"
import { useTranslation } from "react-i18next"

const PriceNumbers: FC<{
  currency: CurrencyCode
  prevPricePerPeriod: number
  pricePerPeriod: number
  isSelected?: boolean
  isDisabled?: boolean
}> = ({ currency, pricePerPeriod, prevPricePerPeriod, isSelected = false, isDisabled = false }) => {
  const { t } = useTranslation()
  return (
    <VFlex
      minH="72px"
      minW="88px"
      paddingY={1.5}
      paddingLeft={5}
      paddingRight={3}
      alignItems="center"
      justifyContent="center"
      color="Base/baseSecondary"
      bgColor="Base/neutralSecondary"
      aria-checked={isSelected}
      _checked={{ bgColor: "Base/accentPrimaryBg2" }}
      aria-disabled={isDisabled}
      _disabled={{ color: "Base/baseDisabled" }}
      sx={{
        maskImage: `url(${tagBgSrc})`,
        maskPosition: "center right",
        maskRepeat: "no-repeat",
        maskType: "alpha",
        maskSize: "100% 80px",
      }}
    >
      <Box textStyle="Subtitle/Hint">
        <Strike>{formatPrice(currency, prevPricePerPeriod)}</Strike>
      </Box>
      <Box
        textStyle="Header/MonospacedSecondary"
        fontFamily="heading"
        aria-checked={isSelected}
        _checked={{ color: "Base/basePrimary" }}
      >
        {formatPrice(currency, pricePerPeriod)}
      </Box>
      <Box textStyle="Subtitle/Hint">{t("prices.free_upper")}</Box>
    </VFlex>
  )
}

export function TimeLimitedOffer({ currency_code }: { currency_code: string }) {
  const { t } = useTranslation()
  return (
    <VFlex borderRadius="20px" bgColor="Base/accentPrimary">
      <Flex
        borderWidth="2px"
        borderColor="Base/accentPrimary"
        bgColor="white"
        py={2}
        pr={2}
        gap={4}
        borderRadius="20px"
        boxShadow="0px 1px 2px 0px rgba(41, 40, 36, 0.06), 0px 8px 28px 0px rgba(41, 40, 36, 0.24);"
      >
        <Flex>
          <Image
            style={{ aspectRatio: "53.33/80" }}
            height="80px"
            maxWidth="none"
            borderRadius="8px"
            src="https://storage.pora.ai/8a8794ace5e782b4491f001807b84909.webp"
          />
        </Flex>
        <VFlex justifyContent="center">
          <Heading as="h3" size="Header/Tertiary">
            {t("food_scanner.get_for_free")}
          </Heading>
          <Box textStyle="Paragraph/Tertiary">{t("food_scanner.today_offer_for_you")}</Box>
        </VFlex>
        {/*todo integrate with backend*/}
        <PriceNumbers
          isSelected
          currency={currency_code}
          pricePerPeriod={0}
          prevPricePerPeriod={20}
        />
      </Flex>
      <Flex color="white" px={4} py={2} justifyContent="center" textStyle="Subtitle/Hint">
        {t("food_scanner.expires_in")} <Timer />
      </Flex>
    </VFlex>
  )
}

const TimeLimitedOfferContainer: FC = () => {
  const selectedPrice = useSelectedPrice()

  return <TimeLimitedOffer currency_code={selectedPrice?.currency_code || "USD"} />
}

export const TimeLimitedOfferBlock: FC<BlockWrapperProps> = (props) => {
  return (
    <BlockWrapper px={6} variant="transparent" {...props}>
      <TimeLimitedOfferContainer />
    </BlockWrapper>
  )
}
