import React, { useEffect, useState, FC } from "react"

import { useUpsaleBlocks } from "~/store/selectors"

import { PaywallBlocks } from "~/generated/paywall"
import { SkipTrialContainer } from "~/pages/Main/SkipTrial/SkipTrialContainer"

import { PaymentStatus, UpsaleContext } from "./components"
import { NavigateSuccessLink, useNavigateSuccess } from "./NavigateSuccessLink"
import { UpSaleAI } from "./UpsaleAI"
import { UpsaleAI2 } from "./UpsaleAI2"
import { UpsaleAI3 } from "./UpsaleAI3"
import { UpsaleAIAssistantContainer, UpsaleAIAssistant2Container } from "./UpsaleAIAssistant"
import { UpsaleBundleContainer } from "./UpsaleBundle"
import { UpsaleNewSubscriptionsContainer } from "./UpsaleNewSubscriptions"
import { UpsaleShopifyProductsContainer } from "./UpsaleShopifyProducts"
import { UpsaleWorkbookContainer } from "./UpsaleWorkbook"
import { useLogPurchase } from "./useLogPurchase"

const upsaleBlockMap = {
  [PaywallBlocks.PB_UPSALE_SKIP_TRIAL]: SkipTrialContainer,
  [PaywallBlocks.PB_UPSALE_WORKBOOK]: UpsaleWorkbookContainer,
  [PaywallBlocks.PB_UPSALE_AI_SCANNER]: UpSaleAI,
  [PaywallBlocks.PB_UPSALE_AI_SCANNER_2]: UpsaleAI2,
  [PaywallBlocks.PB_UPSALE_AI_SCANNER_3]: UpsaleAI3,
  [PaywallBlocks.PB_UPSALE_NEW_SUBSCRIPTIONS]: UpsaleNewSubscriptionsContainer,
  [PaywallBlocks.PB_UPSALE_AI_ASSISTANT_1]: UpsaleAIAssistantContainer,
  [PaywallBlocks.PB_UPSALE_AI_ASSISTANT_2]: UpsaleAIAssistant2Container,
  [PaywallBlocks.PB_UPSALE_SHOPIFY_PRODUCTS]: UpsaleShopifyProductsContainer,
  [PaywallBlocks.PB_UPSALE_BUNDLE]: UpsaleBundleContainer,
} as const

export const ProcessingSuccessContainer: FC = () => {
  useLogPurchase()
  const navigateSuccess = useNavigateSuccess()
  const upsaleBlocks = useUpsaleBlocks()
  const [activeBlockId, setActiveBlockId] = useState(() => upsaleBlocks[0])
  const [subSuccessPaymentShown, setSubSuccessPaymentShown] = useState(false)
  const chooseNextBlock = () => {
    const currentBlockIndex = upsaleBlocks.findIndex((b) => b === activeBlockId)
    if (currentBlockIndex === upsaleBlocks.length - 1) {
      navigateSuccess()
    } else {
      setActiveBlockId(upsaleBlocks[currentBlockIndex + 1])
    }
  }

  useEffect(() => {
    const root = document.getElementById("root")

    if (root) {
      root.scrollTo(0, 0)
    }
  }, [])

  if (!subSuccessPaymentShown) {
    return (
      <PaymentStatus
        variant="appSubscriptionSuccess"
        onClick={() => setSubSuccessPaymentShown(true)}
      />
    )
  }

  const UpsaleBlock = upsaleBlockMap[activeBlockId as keyof typeof upsaleBlockMap]
  if (UpsaleBlock) {
    return (
      <UpsaleContext.Provider
        value={{
          goNext: chooseNextBlock,
          activeBlockId,
        }}
      >
        <UpsaleBlock />
      </UpsaleContext.Provider>
    )
  }

  return <NavigateSuccessLink />
}
