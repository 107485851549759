import React, { FC, ReactNode } from "react"
import {
  Box,
  Heading,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalProps,
  AspectRatio,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"
import { MailChips } from "~/pages/Main/PriceSelectWithPaymentForm"
import { Trans, useTranslation } from "react-i18next"

const Content: FC<{ children: ReactNode; additionalDiscount: string }> = ({
  children,
  additionalDiscount,
}) => {
  const { t } = useTranslation()
  return (
    <VFlex
      gap={6}
      alignItems="center"
      borderRadius="32px"
      paddingX={8}
      pt={16}
      pb={8}
      bgColor="white"
    >
      <VFlex gap={2} alignItems="center">
        <Heading size="Header/Secondary" textAlign="center">
          {t("upsale_statuses.discount_popup.title")}
        </Heading>
        <Box
          textStyle="Paragraph/Primary"
          sx={{
            "& em": {
              color: "Base/accentPrimary",
              fontWeight: 600,
              fontStyle: "normal",
            },
          }}
          textAlign="center"
        >
          <Trans
            i18nKey="upsale_statuses.discount_popup.subtitle"
            values={{ additionalDiscount }}
            components={{
              br: <br />,
              em: <em />,
            }}
          />
        </Box>
      </VFlex>
      <Box w="full">
        <AspectRatio ratio={808 / 518}>
          <Image
            src={t("upsale_statuses.discount_popup.image")}
            alt="Results 34% faster with workbook"
          />
        </AspectRatio>
      </Box>
      <Box w="full">
        <MailChips
          textUnder
          variant="icon"
          colorVariant="grey"
          marginLeft={-6}
          marginRight={-6}
          width="calc(100% + 28px + 28px)"
          paddingTop={0}
          initialCount={56}
        />
      </Box>
      {children}
    </VFlex>
  )
}

export const Step2Modal: FC<
  Pick<ModalProps, "onClose" | "isOpen"> & {
    additionalDiscount: string
  }
> = ({ isOpen, onClose, additionalDiscount }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
        <Content additionalDiscount={additionalDiscount}>
          <Button w="full" variant="action" onClick={onClose}>
            {t("instructions.continue")}
          </Button>
        </Content>
      </ModalContent>
    </Modal>
  )
}
