import React, { FC, MutableRefObject, useCallback } from "react"
import { Box, CenterProps, Flex, FlexProps, useStyleConfig } from "@chakra-ui/react"
import { PaymentProviders } from "~/generated/paywall"
import { noop } from "~/utils"
import { PaymentButtonPaypalContainer, PaymentButtonPaypalSlot } from "../PaymentButtonPaypal"
import { InternalPaymentProviders, PaymentProvidersUnion } from "../InternalPaymentProviders"
import { usePaymentFormContext } from "../PaymentFormContext"
import { ReactComponent as ApplePayLogo } from "./apple-pay-logo.svg"
import { ReactComponent as GooglePayLogo } from "./google-pay-logo.svg"
import { ReactComponent as CardsLogoSmall } from "./cards-logo-small.svg"
import { ReactComponent as PaypalLogo } from "./paypal-logo.svg"

const ApplePaySwitchButton: FC<FlexProps> = (props) => (
  <Flex {...props} justifyContent="center" alignItems="center">
    <ApplePayLogo />
  </Flex>
)

const GooglePaySwitchButton: FC<FlexProps> = (props) => (
  <Flex {...props} justifyContent="center" alignItems="center">
    <GooglePayLogo />
  </Flex>
)

/* TODO: Maybe use RadioButton here? */
const ButtonWrapper: FC<{ isSelected: boolean } & CenterProps> = ({ isSelected, ...rest }) => {
  const styles = useStyleConfig("PaymentSwitchButton", {
    variant: isSelected ? "selected" : "default",
  })
  return <Box w="100%" __css={styles} cursor={isSelected ? "auto" : "pointer"} {...rest} />
}

const ProviderButton: FC<{
  provider: PaymentProvidersUnion
  isSelected: boolean
  onClick: () => void
  paypalRef?: MutableRefObject<null>
  height?: "55px" | "64px"
}> = ({ provider, isSelected, paypalRef, onClick = noop, height = "55px" }) => {
  const {
    value: { isApplePayAvailable },
  } = usePaymentFormContext()

  const defaultProps = { isSelected, onClick, height }
  switch (provider) {
    case InternalPaymentProviders.PALTA_APPLE_PAY:
    case InternalPaymentProviders.RECURLY_APPLE_PAY:
      return (
        <ButtonWrapper {...defaultProps}>
          <ApplePaySwitchButton width="89px" height="37px" color="black" />
        </ButtonWrapper>
      )

    case InternalPaymentProviders.STRIPE_APPLE_PAY:
      if (isApplePayAvailable) {
        return (
          <ButtonWrapper {...defaultProps} bgColor="black">
            <ApplePaySwitchButton width="89px" height="37px" color="white" />
          </ButtonWrapper>
        )
      }
      return null

    case InternalPaymentProviders.RECURLY_GOOGLE_PAY:
      return (
        <ButtonWrapper {...defaultProps}>
          <GooglePaySwitchButton width="112px" />
        </ButtonWrapper>
      )

    case InternalPaymentProviders.RECURLY_PAYPAL:
      return (
        <ButtonWrapper {...defaultProps}>
          <PaypalLogo width="88px" height="46px" />
        </ButtonWrapper>
      )

    case InternalPaymentProviders.RECURLY_CARD:
    case PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD:
      return (
        <ButtonWrapper {...defaultProps}>
          <CardsLogoSmall width="89px" height="37px" />
        </ButtonWrapper>
      )

    case PaymentProviders.PAYMENT_PROVIDER_STRIPE:
      return (
        <ButtonWrapper {...defaultProps}>
          <CardsLogoSmall width="89px" height="37px" />
        </ButtonWrapper>
      )
    case PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL:
      if (paypalRef) {
        return (
          <PaymentButtonPaypalSlot paypalRef={paypalRef} minWidth="116px" w="full" height="55px">
            <ButtonWrapper isSelected={false}>
              <PaypalLogo width="88px" height="46px" />
            </ButtonWrapper>
          </PaymentButtonPaypalSlot>
        )
      }
      return (
        <ButtonWrapper {...defaultProps}>
          <PaypalLogo width="88px" height="46px" />
        </ButtonWrapper>
      )

    case PaymentProviders.PAYMENT_PROVIDER_PAYPAL:
      return (
        <PaymentButtonPaypalContainer minWidth="116px" w="full" height="55px">
          <Box width="full" height="full">
            <ButtonWrapper isSelected={false}>
              <PaypalLogo width="88px" height="46px" />
            </ButtonWrapper>
          </Box>
        </PaymentButtonPaypalContainer>
      )
    default:
      return null
  }
}

export const PaymentProviderSwitch: FC<{
  providers: PaymentProvidersUnion[]
  selected: PaymentProvidersUnion
  paypalRef?: MutableRefObject<null>
  isDisabled?: boolean
  onChange?: (s: PaymentProvidersUnion) => void
  height?: "55px" | "64px"
}> = ({ providers, selected, paypalRef, onChange = noop, isDisabled = false, height = "55px" }) => {
  const changeHandler = useCallback(
    (el: PaymentProvidersUnion) => () => {
      if (isDisabled) {
        return
      }
      onChange(el)
    },
    [isDisabled, onChange]
  )

  return (
    <Flex direction="row" w="100%" gap={2} userSelect="none" height={height}>
      {providers.map((provider) => (
        <ProviderButton
          key={provider}
          provider={provider}
          isSelected={selected === provider}
          onClick={changeHandler(provider)}
          paypalRef={paypalRef}
          height={height}
        />
      ))}
    </Flex>
  )
}
