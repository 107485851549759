import { Box, Flex, Image } from "@chakra-ui/react"
import React, { ReactNode, useCallback } from "react"
import { AttributedStringType } from "~/components/AttributedString"
import { HeaderHeadingPartWithLogo } from "~/pages/Main/Blocks/HeaderBlock/HeaderHeadingPart"
import { noop } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { CosmeticsIcon, SmileIcon, TargetIcon } from "./Icons"

export const HeaderPrePaywall = ({
  title,
  resultsDate,
  timeBlock,
  resultsText,
  graphImg,
  onTimerBlockClick = noop,
  programLengthWeeks,
  timerDescription,
}: {
  title: AttributedStringType
  resultsDate: string
  timeBlock: ReactNode
  resultsText: string
  graphImg: string
  onTimerBlockClick?: () => void
  programLengthWeeks: number
  timerDescription: string
}) => {
  const log = useAmplitude()
  const onTimerBlockClickFn = useCallback(() => {
    log.click({ source: "header_timer" })
    onTimerBlockClick()
  }, [log, onTimerBlockClick])

  return (
    <>
      <Box mb={8} w="full">
        <HeaderHeadingPartWithLogo title={title} />
      </Box>
      <Flex textStyle="RegularParagraph" gap={4} px={6} flexDirection="column" mb={8}>
        <Flex alignItems="center" gap={4}>
          <TargetIcon fontSize="36px" />
          <Box>
            <Box textStyle="MediumSubtitleSecondary" as="span">
              Get visible results
            </Box>{" "}
            by {resultsDate} and&nbsp;feel great
          </Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <CosmeticsIcon fontSize="36px" />
          <Box>Build skin care routine to improve the&nbsp;result</Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <SmileIcon fontSize="36px" />
          <Box>Boost your confidence level and&nbsp;shine</Box>
        </Flex>
      </Flex>
      <Box
        onClick={onTimerBlockClickFn}
        borderRadius="3xl"
        borderWidth={1}
        borderColor="Base/neutralActive"
        bgColor="Base/neutralSecondary"
        mb={2}
        p={6}
      >
        <Box mb={2} textStyle="RegularParagraphSecondary">
          {timerDescription}
        </Box>
        <Box mb={2} textStyle="MediumSubtitlePrimary" textColor="Base/accentFocused">
          Time remaining {timeBlock}
        </Box>
        <Box textStyle="RegularParagraphSecondary" textColor="Base/baseSecondary">
          Scroll down to get started!
        </Box>
      </Box>
      <Box
        borderRadius="3xl"
        borderWidth={1}
        borderColor="Base/neutralActive"
        bgColor="Base/neutralSecondary"
      >
        <Box px={8} pt={4} pb={3} textAlign="center" textStyle="RegularParagraphSecondary">
          {resultsText}
        </Box>
        <Box borderRadius="norm" bgColor="white" mx={2} mb={2} p={6}>
          <Image src={graphImg} alt="graph" mb={2} />
          <Flex justifyContent="space-between" alignItems="center">
            <Box textStyle="MediumSubtitleTertiary">Today</Box>
            <Box textStyle="MediumSubtitleTertiary">in {programLengthWeeks} weeks</Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}
