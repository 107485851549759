import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import { Text, Image } from "@chakra-ui/react"

import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"

import { PAYMENT_FORM_ID } from "~/constants"
import { useScrollToOffer } from "~/pages/Main/scrollToOffer"
import { InvoiceSkipTrialContainer } from "~/pages/Main/Invoice/InvoiceSkipTrialContainer"
import { InvoiceSkipTrialDiscountedContainer } from "~/pages/Main/Invoice/InvoiceSkipTrialDiscountedContainer"
import { UpsaleContext } from "~/pages/Processing/components"
import { stripeCreateSkipTrialIntent } from "~/api/api"
import { useAuthInfo } from "~/utils/useAuthInfo"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"

import { Logo } from "./Icons"
import { DiscountPrefOfferModal } from "./DiscountPrefOfferModal"

enum SkipTrialState {
  OFFER = "OFFER",
  DISCOUNT_PRE_OFFER = "DISCOUNT_PRE_OFFER",
  DISCOUNT_OFFER = "DISCOUNT_OFFER",
  SUCCESS = "SUCCESS",
}

export type SkipTrialProps = {
  skipTrialPrice: PriceContentV2_PriceItem
  skipTrialDiscountedPrice?: PriceContentV2_PriceItem
}

export function SkipTrial({ skipTrialPrice, skipTrialDiscountedPrice }: SkipTrialProps) {
  // probably it's better to use router here
  const [processState, setProcessState] = useState<SkipTrialState>(SkipTrialState.OFFER)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const scrollToOffer = useScrollToOffer("after_discount_modal")
  const log = useAmplitude()
  const { goNext } = useContext(UpsaleContext)
  const continueWithTrial = useCallback(() => {
    switch (processState) {
      case SkipTrialState.OFFER:
        setProcessState(SkipTrialState.DISCOUNT_PRE_OFFER)
        break
      case SkipTrialState.DISCOUNT_OFFER:
        setProcessState(SkipTrialState.SUCCESS)
        break
    }
  }, [processState])

  useEffect(() => {
    if (processState === SkipTrialState.SUCCESS) {
      goNext()
    }
  }, [processState, goNext])

  useEffect(() => {
    log.skipTrialView({
      state: processState,
    })
  }, [log, processState])

  const stripe = useStripe()
  const showError = (error: string) => {
    log.skipTrialError({ error })
    console.error(error)
    alert(error)
  }

  const continueDiscountPreOffer = () => {
    setProcessState(SkipTrialState.DISCOUNT_OFFER)
    scrollToOffer()
  }
  const { user } = useAuthInfo()
  const { userId } = user ?? {}

  const upgradeSubscription = async () => {
    log.skipTrialConfirm()
    /* TODO: remove provider hardcode */
    const priceId = skipTrialPrice?.payment_providers?.stripe?.price_id
    if (!priceId) {
      showError("no skipTrialPrice provided")
      return
    }
    const success = await payForTheSubUpgrade(priceId)
    if (success) {
      log.skipTrialConfirmSuccess()
      setProcessState(SkipTrialState.SUCCESS)
    }
  }

  const upgradeSubscriptionWithDiscount = async () => {
    log.skipTrialConfirmDiscount()
    /* TODO: remove provider hardcode */
    const priceId = skipTrialDiscountedPrice?.payment_providers?.stripe?.price_id
    if (!priceId) {
      showError("no skipTrialDiscountedPrice provided")
      return
    }
    const success = await payForTheSubUpgrade(priceId)
    if (success) {
      log.skipTrialConfirmDiscountSuccess()
      setProcessState(SkipTrialState.SUCCESS)
    }
  }

  const payForTheSubUpgrade = async (priceId: string): Promise<boolean> => {
    setPaymentLoading(true)
    if (!userId) {
      showError("No userId found")
      return false
    }

    try {
      const clientSecret = await stripeCreateSkipTrialIntent({ userId, priceId })
      const { error } = await stripe!.confirmCardPayment(clientSecret)
      if (error) {
        showError("Payment error, please try again: " + error.message)
        // The payment failed -- ask your customer for a new payment method.
        return false
      } else {
        // The payment has succeeded.
        return true
      }
    } catch (e) {
      console.error(e)
      showError("Error happened")
      return false
    } finally {
      setPaymentLoading(false)
    }
  }

  const showDiscountedInvoiceBlock =
    processState === SkipTrialState.DISCOUNT_OFFER ||
    processState === SkipTrialState.DISCOUNT_PRE_OFFER

  const buttonProps = showDiscountedInvoiceBlock
    ? ({
        onClick: upgradeSubscriptionWithDiscount,
        children: "Skip trial and start program",
      } as const)
    : ({
        onClick: upgradeSubscription,
        children: "Start program without trial",
      } as const)

  return (
    <Box bgColor="white" px={6} pb="156px">
      <Flex mb={4} justifyContent="center" alignItems="center" height="56px" as="header">
        <Logo />
      </Flex>
      <Heading mb={6} as="h1" size="Header/Primary">
        Looking to increase your chances by <em>79%</em>?
      </Heading>
      <Flex flexDirection="column" gap={6}>
        <Text textStyle="Paragraph/Primary">
          Psychologically speaking, people feel&nbsp;more obligated to follow through once they have
          made a strong commitment.
        </Text>
        <Text textStyle="Paragraph/Primary">
          This principle is also applicable to skin care routines. You just need to commit.
        </Text>
        <Text textStyle="Subtitle/Secondary">
          We see Lóvi users who commit today&nbsp;by skipping their trial period increase&nbsp;their
          chances of significantly improving their skin quality by 79%.
        </Text>
      </Flex>
      <Flex justifyContent="center" my={8}>
        <Image h="240px" src="https://storage.pora.ai/252b59b717147e434578064adce09a1d.webp" />
      </Flex>
      <Box id={PAYMENT_FORM_ID}>
        {showDiscountedInvoiceBlock ? (
          <InvoiceSkipTrialDiscountedContainer />
        ) : (
          <InvoiceSkipTrialContainer />
        )}
      </Box>
      <ButtonSlideUpContainer visible={true}>
        <Flex direction="column" gap={2}>
          <Button isLoading={paymentLoading} w="full" variant="action" {...buttonProps} />
          <Button
            disabled={paymentLoading}
            onClick={continueWithTrial}
            w="full"
            variant="secondary"
          >
            Continue with trial
          </Button>
        </Flex>
      </ButtonSlideUpContainer>
      {processState === SkipTrialState.DISCOUNT_PRE_OFFER && (
        <DiscountPrefOfferModal handleConfirm={continueDiscountPreOffer} />
      )}
    </Box>
  )
}
