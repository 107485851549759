import { PeriodUnit } from "./PeriodUnit"
import i18n from "~/i18n"

const t = i18n.t
export const formatLengthWithUnit = (trialUnit: PeriodUnit, trialLength: number): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return t("month", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return t("week", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_DAY:
      return t("day", { count: trialLength })
    default:
      return trialLength + " " + trialUnit
  }
}

export const formatLengthWithUnit2 = (trialUnit: PeriodUnit, trialLength: number): string =>
  trialLength > 1
    ? formatUnitWithPrefixLength(trialUnit, trialLength)
    : formatLengthWithUnit(trialUnit, trialLength)

export const formatUnitWithPrefixLength = (trialUnit: PeriodUnit, trialLength: number): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return t("month_prefix", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return t("week_prefix", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_DAY:
      return t("day_prefix", { count: trialLength })
    default:
      return trialLength + " " + trialUnit
  }
}

export const formatUnitWithPrefixLengthDash = (
  trialUnit: PeriodUnit,
  trialLength: number
): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return t("month_prefix_dash", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return t("week_prefix_dash", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_DAY:
      return t("day_prefix_dash", { count: trialLength })
    default:
      return trialLength + " " + trialUnit
  }
}

export const formatUnitWithPrefixOnPlural = (
  trialUnit: PeriodUnit,
  trialLength: number
): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      if (trialLength == 12) {
        return t("year")
      }
      return t("month_prefix_only_plural", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return t("week_prefix_dash", { count: trialLength })
    case PeriodUnit.PERIOD_UNIT_DAY:
      return t("day_prefix_dash", { count: trialLength })
    default:
      return trialLength + " " + trialUnit
  }
}
