import React, { FC, ReactNode, useEffect } from "react"
import {
  Box,
  BoxProps,
  Button,
  createIcon,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { Timer, useTimerContext, VFlex } from "~/components"
import { formatLengthWithUnit, formatPrice, formatUnitWithPrefixLength } from "~/utils"
import { Strike2 } from "~/shared/Strike"

import { FlashIcon } from "./icons"
import shieldSrc from "./assets/shield.svg?url"
import { useTranslation } from "react-i18next"

export * from "./WhyLoviPart"
export * from "./PaymentStatus"
export * from "./UpsaleContext"

const DiscountShield: FC<BoxProps> = (props) => (
  <Heading
    as="h3"
    position="absolute"
    bgImage={shieldSrc}
    bgPosition="top"
    bgRepeat="no-repeat"
    color="Base/neutralPrimary"
    size="Header/Secondary"
    w="71px"
    h="112px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    mt={-2}
    {...props}
  />
)
export const DiscountCard: FC<{ discount: string }> = ({ discount }) => {
  const { t } = useTranslation()
  return (
    <Box
      pl={4}
      pr={6}
      py={8}
      id="discount-card"
      layerStyle="attentionFrame"
      textStyle="Subtitle/AccentPrimary"
      borderWidth="1px"
      bgColor="Base/accentPrimaryBg"
      borderColor="Base/accentPrimary"
      alignItems="center"
      mb={10}
    >
      <Box position="relative" top={-8} alignSelf="start">
        <DiscountShield position="absolute">
          {discount}
          <br />
          OFF
        </DiscountShield>
      </Box>
      <Box whiteSpace="pre-line" paddingLeft="86px">
        {t("discount_card.additional_discount", { discount })}
      </Box>
    </Box>
  )
}

export const PromoPriceText: FC<{
  price: PriceContentV2_PriceItem
}> = ({ price }) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={1}>
      <Heading
        as="h3"
        size="Header/Tertiary"
        textAlign="center"
        color="Base/accentPrimary"
        marginBottom={1}
      >
        {t("upsale_bundle.price", {
          price: formatPrice(price.currency_code, parseFloat(price.economy_price)),
          period: formatLengthWithUnit(
            price.subscription_period_unit,
            price.subscription_period_length
          ),
        })}
      </Heading>
      <Text textStyle="Paragraph/Secondary" color="Base/baseSecondary" textAlign="center">
        {t("upsale_bundle.original_price_was", {
          price: formatPrice(price.currency_code, parseFloat(price.previous_price)),
        })}{" "}
        —{" "}
        <Box as="span" color="Base/accentPrimary">
          {t("upsale_bundle.save", {
            discount: price.economy_percent,
          })}
        </Box>
      </Text>
    </VFlex>
  )
}

export const SubmitButtons: FC<{
  isLoading: boolean
  onPay: () => void
  onSkip: () => void
}> = ({ isLoading, onPay, onSkip }) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={2}>
      <Button isLoading={isLoading} onClick={onPay} w="full" variant="action">
        {t("upsale_bundle.get_my_bundle")}
      </Button>
      <Button
        color="Base/baseSecondary"
        disabled={isLoading}
        onClick={onSkip}
        w="full"
        variant="secondary"
      >
        {t("upsale_bundle.skip")}
      </Button>
    </VFlex>
  )
}

const UnlimitedTag = () => {
  const { t } = useTranslation()
  return (
    <Flex
      alignItems="center"
      borderRadius="100px"
      color="white"
      bgColor="Base/accentSecondary"
      textStyle="Subtitle/Secondary"
      gap={1}
      py={2}
      px={3}
    >
      <Icon as={FlashIcon} />
      <Box>{t("upsale_bundle.unlimited")}</Box>
    </Flex>
  )
}

const EconomyPricePerUnit: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <>
    {formatPrice(price.currency_code, parseFloat(price.economy_price))}/
    {formatLengthWithUnit(price.subscription_period_unit, price.subscription_period_length).replace(
      "month",
      "mo"
    )}
  </>
)

const PreviousPrice: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <>{formatPrice(price.currency_code, parseFloat(price.previous_price))}</>
)

export const PriceBox: FC<{ price: PriceContentV2_PriceItem; children: ReactNode }> = ({
  price,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <VFlex p={6} layerStyle="attentionFrame" gap={5}>
      <VFlex alignItems="center" textStyle="Header/Secondary">
        <Heading as="h2" size="Header/Secondary" textAlign="center">
          <Box>{t("upsale_bundle.get_it_now")}</Box>
          <Box color="Base/accentPrimary">
            {t("upsale_bundle.economy", { discount: price.economy_percent })}
          </Box>
        </Heading>
      </VFlex>
      <VFlex textStyle="Paragraph/Primary" gap={3}>
        <Flex justifyContent="space-between" gap="1em" align="center">
          <Box>{t("upsale_bundle.original_price")}</Box>
          <Strike2>
            <PreviousPrice price={price} />
          </Strike2>
        </Flex>
        <Divider borderColor="Base/baseTertiary" />
        <Flex justifyContent="space-between" gap="1em" align="center">
          <Box>{t("upsale_bundle.current_price")}</Box>
          <Box textStyle="Subtitle/Secondary">
            <EconomyPricePerUnit price={price} />
          </Box>
        </Flex>
        <Flex justifyContent="space-between" gap="1em" align="center">
          <Box>{children}</Box>
          <UnlimitedTag />
        </Flex>
      </VFlex>
    </VFlex>
  )
}

export const LegalNotice: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => {
  const { t } = useTranslation()
  return (
    <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
      {t("upsale_bundle.legal_notice", {
        will_be_charged: formatPrice(price.currency_code, parseFloat(price.economy_price)),
        length: formatUnitWithPrefixLength(
          price.subscription_period_unit,
          price.subscription_period_length
        ),
      })}
    </Box>
  )
}

const BenefitCard = ({ image, title }: { image: string; title: string }) => {
  return (
    <VFlex
      width="152px"
      flexShrink={0}
      p={4}
      layerStyle="attentionFrame"
      alignItems="flex-start"
      gap={3}
    >
      <Flex>
        <Image height="64px" src={image} />
      </Flex>
      <Flex textStyle="Subtitle/Secondary">{title}</Flex>
    </VFlex>
  )
}
export const BenefitCardList = () => {
  const { t } = useTranslation()
  const benefits = t("upsale_bundle.benefits", { returnObjects: true })
  return (
    <Flex mx={-6} pl={6} pr={6} gap={2} overflow="scroll">
      {benefits.map((conf) => (
        <BenefitCard key={conf.title} {...conf} />
      ))}
    </Flex>
  )
}

const CircleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    width: "24px",
    height: "24px",
    color: "Base/accentSecondary",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4472 1.00179C16.482 0.913134 21.0209 4.13053 22.5322 8.84744C24.0029 13.4373 21.8843 18.3055 17.8801 21.0789C14.05 23.7317 8.93393 23.6114 5.16344 20.8774C1.48145 18.2076 0.16465 13.5098 1.52173 9.2174C2.94015 4.73094 6.66412 1.08601 11.4472 1.00179Z"
      fill="currentColor"
    />
  ),
})

const WHY_LOVI_LIST_DATA = [
  {
    title: "Fit score ",
    text: "The Lóvi’s Fit Score objectively assesses skincare products on safety, cost, efficacy, ingredient concentration, and FDA compliance. Experience our detailed tailored to your skin analysis firsthand!",
  },
  {
    title: "Product’s Alternatives",
    text: "Join the 71% of consumers seeking better skincare solutions. Lóvi's extensive database of over 500K products ensures you find the perfect match for your skin.",
  },
  {
    title: "Unbiased Insights",
    text: "As a 100% independent project, Lóvi focuses on the science of skincare, providing impartial recommendations based on product composition, not brand hype.",
  },
  {
    title: "Safety",
    text: "Lóvi's recommendations are safe for all skin types, including sensitive skin, and for pregnant or breastfeeding women. Your security is our priority; we never share your data.",
  },
]

/* use list from NumberedListCard */
export const WhyLoviList = () => {
  const { t } = useTranslation()
  const whyLoviList = t("upsale_bundle.why_lovi_list", { returnObjects: true })
  return (
    <VFlex gap={4}>
      <Heading as="h2" size="Header/Secondary">
        {t("upsale_bundle.why_lovi")}
      </Heading>
      <VFlex gap={4}>
        {whyLoviList.map(({ text, title }, i) => {
          return (
            <Flex key={i} gap={2}>
              <Flex as="span" display="flex" pos="relative" alignSelf="flex-start">
                <CircleIcon />
                <Box
                  textStyle="Subtitle/Tertiary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  pos="absolute"
                  left="0"
                  top="0"
                  right="0"
                  bottom="0"
                >
                  {i + 1}
                </Box>
              </Flex>
              <VFlex gap={1.5} top="-3px" pos="relative">
                <Box textStyle="Subtitle/Primary">{title}</Box>
                <Box textStyle="Paragraph/Secondary">{text}</Box>
              </VFlex>
            </Flex>
          )
        })}
      </VFlex>
    </VFlex>
  )
}

export const TimerBlock = () => {
  const { start, stop } = useTimerContext()
  useEffect(() => {
    start(15 * 60)
    return stop
  }, [])
  const { t } = useTranslation()

  return (
    <Box
      mx={-6}
      color="white"
      position="sticky"
      mt={6}
      bgColor="Base/accentPrimary"
      p={4}
      textAlign="center"
      right={0}
      left={0}
      bottom={0}
    >
      {t("this_offer_ends")} <Timer />
    </Box>
  )
}
