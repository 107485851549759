import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import { SkinIssues } from "~/generated/paywall"
import icons from "~/shared/issueIcons"
import i18n from "~/i18n"

type SvgIconComponent = ComponentWithAs<"svg", IconProps>

type Item = {
  title: string
  icon: SvgIconComponent
}

const t = i18n.t

const issueToItemData: Partial<Record<SkinIssues, Item>> = {
  [SkinIssues.SKIN_ISSUE_UNSPECIFIED]: {
    title: "",
    icon: icons["unknown"],
  },
  [SkinIssues.SKIN_ISSUE_WRINKLES]: {
    title: t("skin_issues.wrinkles"),
    icon: icons["wrinkles"],
  },
  [SkinIssues.SKIN_ISSUE_PIGMENTATION]: {
    title: t("skin_issues.pigmentation"),
    icon: icons["pigmentation"],
  },
  [SkinIssues.SKIN_ISSUE_ROUGH_TEXTURE]: {
    title: t("skin_issues.rough_texture"),
    icon: icons["postacne"],
  },
  [SkinIssues.SKIN_ISSUE_DULLNESS]: {
    title: t("skin_issues.dullness"),
    icon: icons["dullFace"],
  },
  [SkinIssues.SKIN_ISSUE_ACNE]: {
    title: t("skin_issues.acne"),
    icon: icons["acne"],
  },
  [SkinIssues.SKIN_ISSUE_REDNESS]: {
    title: t("skin_issues.redness"),
    icon: icons["redness"],
  },
  [SkinIssues.SKIN_ISSUE_DRYNESS]: {
    title: t("skin_issues.dryness"),
    icon: icons["dry"],
  },
  [SkinIssues.SKIN_ISSUE_ENLARGED_PORES]: {
    title: t("skin_issues.enlarged_pores"),
    icon: icons["blackheads"],
  },
  [SkinIssues.SKIN_ISSUE_OILY]: {
    title: t("skin_issues.oily"),
    icon: icons["oil"],
  },
  [SkinIssues.SKIN_ISSUE_PUFFINESS]: {
    title: t("skin_issues.puffiness"),
    icon: icons["eyeUnderlined"],
  },
  [SkinIssues.SKIN_ISSUE_DOUBLE_CHIN]: {
    title: t("skin_issues.double_chin"),
    icon: icons["faceshape"],
  },
  [SkinIssues.SKIN_ISSUE_UNEVEN_SKIN_TONE]: {
    title: t("skin_issues.uneven_skin_tone"),
    icon: icons["acne"],
  },
  [SkinIssues.SKIN_ISSUE_TEXTURAL_IRREGULARITIES]: {
    title: t("skin_issues.textural_irregularities"),
    icon: icons["pigmentation"],
  },
  [SkinIssues.SKIN_ISSUE_CROWS_FEET]: {
    title: t("skin_issues.crows_feet"),
    icon: icons["crowfeet"],
  },
  [SkinIssues.SKIN_ISSUE_DARK_CIRCLES]: {
    title: t("skin_issues.dark_circles"),
    icon: icons["darkCircles"],
  },
  [SkinIssues.SKIN_ISSUE_SAGGING_SKIN]: {
    title: t("skin_issues.sagging_skin"),
    icon: icons["puffiness"],
  },
}

export const issueToItem = (issue: SkinIssues): Item => {
  return issueToItemData[issue] ?? (issueToItemData[SkinIssues.SKIN_ISSUE_UNSPECIFIED] as Item)
}
