import React, { FC } from "react"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { StarIcon } from "./Icons"
import { useTranslation } from "react-i18next"

export type Review = {
  usageLength: number
  title: string
  text: string
}

const Stars: FC = () => (
  <Flex>
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
  </Flex>
)

const Reviews5Item: FC<Review> = ({ usageLength, text, title }) => {
  const { t } = useTranslation()
  return (
    <Flex
      borderWidth={1}
      borderColor="Base/neutralSecondary"
      flexDirection="column"
      p={4}
      borderRadius="3xl"
      bgColor="Base/neutralPrimary"
    >
      <Flex w="full" justifyContent="space-between" alignItems="center" mb={3}>
        <Stars />
        <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
          {t("review_item_5.usage", { usageLength })}
        </Box>
      </Flex>
      <Heading
        mb={1}
        as="h5"
        size="Subtitle/Secondary"
        textStyle="Subtitle/Secondary"
        variant="text"
      >
        {title}
      </Heading>
      <Text textStyle="Paragraph/Secondary">{text}</Text>
    </Flex>
  )
}

export const Reviews6: FC<{ reviews: Review[] }> = ({ reviews }) => {
  if (reviews.length < 1) {
    return <div>reviews are loading...</div>
  }

  return (
    <Flex flexDirection="column" overflow="hidden">
      {reviews[0] && (
        <Box>
          <Reviews5Item {...reviews[0]} />
        </Box>
      )}
    </Flex>
  )
}
