import React, { FC, useCallback, useEffect, useState } from "react"
import { Box, Button, Center, useBoolean } from "@chakra-ui/react"

import { VFlex } from "~/components/VFlex"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import { useUpsaleWorkbookData } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMakePurchase } from "~/utils/useMakePurchase"

import { Logo } from "~/pages/Main/SkipTrial/Icons"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"

import { PaymentStatus, PaymentSuccessVariant } from "../components"

import { Step2Modal } from "./Step2Modal"
import { WorkbookHeader, WorkbookCard, DiscountCard, PriceInfo } from "./WorkbookCard"

type Step = 0 | 1 | 2
export const UpSaleWorkbook: FC<{
  step: Step
  isLoading: boolean
  price: PriceContentV2_PriceItem
  discountPrice: PriceContentV2_PriceItem
  onClickPay: () => void
  onClickNext: () => void
}> = ({ step, onClickPay, onClickNext, isLoading = false, price, discountPrice }) => {
  useEffect(() => {
    if (step == 2) {
      document.querySelector("#discount-card")?.scrollIntoView()
    }
  }, [step])
  return (
    <>
      <VFlex gap={6} bgColor="white" paddingBottom="160px" paddingX={6}>
        <Box>
          <Center mb={4} height="56px" as="header">
            <Logo />
          </Center>
          <WorkbookHeader />
        </Box>
        <VFlex gap={2}>
          <WorkbookCard>
            <PriceInfo isDiscounted={step === 2} price={price} priceDiscounted={discountPrice} />
          </WorkbookCard>
          {step === 2 && <DiscountCard discount={discountPrice.subscription_text} />}
        </VFlex>
        <Step2Modal isOpen={step === 1} onClose={onClickNext} />
      </VFlex>
      <ButtonSlideUpContainer visible>
        <VFlex gap={2}>
          <Button isLoading={isLoading} w="full" variant="action" onClick={onClickPay}>
            Buy Workbook
          </Button>
          <Button disabled={isLoading} onClick={onClickNext} w="full" variant="secondary">
            Continue without Workbook
          </Button>
        </VFlex>
      </ButtonSlideUpContainer>
    </>
  )
}

export const UpsaleWorkbookContainer: FC = () => {
  const [paymentPageVariant, setPaymentPageVariant] = useState<PaymentSuccessVariant>()

  const makePurchase = useMakePurchase()

  const { price, price_discounted: discountPrice } = useUpsaleWorkbookData()
  const [step, setStep] = useState<Step>(0)
  const [isLoading, setLoading] = useBoolean()
  const log = useAmplitude()
  useEffect(() => {
    log.upsaleWorkbookView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  const onFail = useCallback(() => {
    log.upsaleWorkbookBuyFail()
    setPaymentPageVariant("workbookFail")
  }, [log, setPaymentPageVariant])

  const onSuccess = useCallback(() => {
    log.upsaleWorkbookBuySuccess()
    setPaymentPageVariant("workbookSuccess")
  }, [log, setPaymentPageVariant])

  const onNext = useCallback(() => {
    if (step === 0) {
      setStep(1)
      return
    }
    if (step === 1) {
      setStep(2)
      return
    }
    if (step === 2) {
      log.upsaleWorkbookExit()
      onFail()
      return
    }
  }, [step, setStep])

  const onPay = useCallback(async (): Promise<boolean> => {
    const selectedPrice = step === 0 ? price : discountPrice
    try {
      if (!selectedPrice) {
        throw new Error("Unknown Price")
      }
      setLoading.on()
      log.upsaleWorkbookBuy({ priceId: selectedPrice.id })
      const result = await makePurchase(selectedPrice)
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleWorkbookBuyError()
      onFail()
    }
    return false
  }, [step, makePurchase])

  if (!price || !discountPrice) {
    return null
  }

  return (
    <>
      {!paymentPageVariant && (
        <UpSaleWorkbook
          step={step}
          isLoading={isLoading}
          onClickNext={onNext}
          onClickPay={onPay}
          price={price}
          discountPrice={discountPrice}
        />
      )}
      {paymentPageVariant && <PaymentStatus variant={paymentPageVariant} />}
    </>
  )
}
